import { useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import React, { Suspense } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { VERSION } from 'app'
import {
  AppBar,
  Button,
  ChevronLeftIcon,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuIcon,
  Toolbar,
  Typography,
} from 'components'
import { useTitle } from 'contexts/Title'
import { PrivateRoutes } from 'routes/PrivateRoutes'
import { clearCurrentUserToken } from 'utils'

import { useStyles } from './styles'

export const Default = () => {
  const isMobile = useMediaQuery('(max-width:900px)')
  const classes = useStyles()
  const [open, setOpen] = React.useState(isMobile)
  const navigate = useNavigate()
  const { title } = useTitle()

  const handleToggleDrawer = () => {
    setOpen(!open)
  }

  const handleOnClick = () => {
    clearCurrentUserToken()
    navigate('/')
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleToggleDrawer}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {title}
          </Typography>
          <Button variant="outlined" color="secondary" onClick={handleOnClick}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <div>v{VERSION}</div>
          <IconButton onClick={handleToggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {PrivateRoutes[0].children.map((route) => (
            <ListItem
              button
              key={route.path}
              onClick={() => navigate(route.path)}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText primary={route.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.container}>
          <Suspense fallback={<CircularProgress />}>
            <Outlet />
          </Suspense>
        </div>
      </main>
    </div>
  )
}
