import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  path: string
  params?: { replace?: boolean; state?: Record<string, unknown> | null } // TODO replace with correct type from react-router-dom when released
}

export const Redirect = ({ path, params }: Props) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(path, params)
  }, [navigate, params, path])
  return null
}
