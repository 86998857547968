import React, { createContext, useState, useContext, useEffect } from 'react'

export interface Title {
  title: string | undefined
  setTitle: (value: string | undefined) => void
}

const TitleDefaults: Title = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTitle: (value: string | undefined) => {},

  title: undefined,
}

const TitleContext = createContext(TitleDefaults)

export const useTitle = (title?: string) => {
  const context = useContext(TitleContext)

  const { setTitle } = context

  useEffect(() => {
    if (title) setTitle(title)
  }, [setTitle, title])

  return context
}

const TitleProvider: React.FunctionComponent = (props) => {
  const [title, setTitle] = useState<string | undefined>(TitleDefaults.title)

  return (
    <TitleContext.Provider value={{ setTitle, title }}>
      <>{props?.children}</>
    </TitleContext.Provider>
  )
}

export default TitleProvider
