import { CircularProgress } from '@material-ui/core'
import React from 'react'

export const Loader = ({ size = 60 }: { size?: number }) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={size} style={{ color: '#48B7E3' }} />
    </div>
  )
}
