import { decode } from 'jsonwebtoken'
import {
  clearCurrentUserToken,
  getCurrentUserToken,
  setCurrentUserToken,
} from 'utils'

type RefreshingPromise = Promise<string | null> | null

class TokenManager {
  private static instance: TokenManager
  private refreshingPromise: RefreshingPromise = null

  public static getInstance(): TokenManager {
    if (!TokenManager.instance) {
      TokenManager.instance = new TokenManager()
    }
    return TokenManager.instance
  }

  public getToken(): string | null {
    return getCurrentUserToken()
  }

  public isTokenValid(): boolean {
    const token = this.getToken()
    if (!token) {
      return false
    }
    const decoded = decode(token) as { exp: number }
    return decoded?.exp * 1000 > Date.now()
  }

  public async getValidToken(): Promise<string | null> {
    if (this.isTokenValid()) {
      return this.getToken()
    }

    if (this.refreshingPromise) {
      // Wait for the ongoing refresh to complete
      return this.refreshingPromise
    }

    // Start refreshing the token
    this.refreshingPromise = this.refreshToken()

    try {
      const newToken = await this.refreshingPromise
      return newToken
    } catch (error) {
      // Handle token refresh failure
      clearCurrentUserToken()
      throw error
    } finally {
      this.refreshingPromise = null
    }
  }

  private async refreshToken(): Promise<string | null> {
    try {
      const response = await getRefreshToken()
      if (response) {
        const result = (await response.json()) as {
          data: { refresh: { token: string } } | null
        }
        if (result.data?.refresh.token) {
          const newToken = result.data.refresh.token
          setCurrentUserToken(newToken)
          return newToken
        } else {
          throw new Error('No token in refresh response')
        }
      } else {
        throw new Error('No response from refresh token request')
      }
    } catch (error) {
      console.error('Error refreshing token:', error)
      throw error
    }
  }
}

export const tokenManager = TokenManager.getInstance()

export const getRefreshToken: () => Promise<Response> = async () => {
  return await fetch(`${process.env.REACT_APP_API!}`, {
    body: JSON.stringify({
      query: `
        mutation {
          refresh {
            token
          }
        }
      `,
    }),
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  })
}
