import 'index.css'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import React from 'react'
import ReactDOM from 'react-dom'

import { VERSION } from 'app'

import { Container } from './Container'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

Sentry.init({
  dsn: 'https://e06bcd783c6b48ed8c757ad87eaa686a@o174489.ingest.sentry.io/5431355',
  enabled: process.env.REACT_APP_SENTRY_ENV === ('production' || 'staging'),
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new Integrations.BrowserTracing()],
  release: `admin-ui@${VERSION}`,
  tracesSampleRate: 0.01,
})

dayjs.extend(advancedFormat)

ReactDOM.render(
  <React.StrictMode>
    <Container />
  </React.StrictMode>,
  document.getElementById('root'),
)

serviceWorkerRegistration.unregister()
reportWebVitals()
