import { makeStyles } from 'styles'

export const useStyles = makeStyles((theme) => ({
  row: {
    '&:hover': {
      backgroundColor: '#ABE6AB',
    },
    backgroundColor: '#C4FFC4',
  },
}))
