import { Button as B } from '@material-ui/core'
import React from 'react'

import { CircularProgress } from 'components'

export const Button = (props: any) => {
  const { loading, ...rest } = props
  return (
    <B
      {...{
        ...rest,
        children: props.loading ? (
          <CircularProgress size={27} color="inherit" />
        ) : (
          props.children
        ),
        disabled: props.loading || props.disabled,
      }}
    />
  )
}
