import { isNil } from 'ramda'
import { useRoutes } from 'react-router-dom'

import { getCurrentUserToken } from 'utils'

import { PrivateRoutes } from './PrivateRoutes'
import { PublicRoutes } from './PublicRoutes'

export const Routes = () => {
  const routes = isNil(getCurrentUserToken()) ? PublicRoutes : PrivateRoutes

  return useRoutes(routes)
}
