import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { Container, CircularProgress } from 'components'

export const Auth = () => (
  <Container component="main" maxWidth="xs">
    <Suspense fallback={<CircularProgress />}>
      <Outlet />
    </Suspense>
  </Container>
)
