import {
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Dialog,
} from '@material-ui/core'
import * as React from 'react'

export const AlertDialog = (props: {
  open: boolean
  handleCancel: () => void
  handleConfirm: () => void
  title: string
  children: React.ReactNode
}) => {
  const { open, handleCancel, handleConfirm, title, children, ...rest } = props
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...rest}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleConfirm}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
