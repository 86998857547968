export const getCurrentUserToken = () => localStorage.getItem('authorization')

export const setCurrentUserToken = (token: string) =>
  localStorage.setItem('authorization', token)

export const clearCurrentUserToken = () => {
  localStorage.removeItem('authorization')
  indexedDB.deleteDatabase('graphcache')
}

export const getCurrentEmail = () => localStorage.getItem('jeladmin:email')

export const setCurrentEmail = (email: string) =>
  localStorage.setItem('jeladmin:email', email)

export const clearCurrentEmail = () => {
  localStorage.removeItem('jeladmin:email')
}
