export const FormatLongList = (list: string[], threshold: number) => {
  let listDisplay: string[] = []
  if (list.length > threshold) {
    const [a, b, c, ...rest] = list

    listDisplay.push(a, b, c, `and ${rest.length} other(s)`)
  } else {
    listDisplay = list
  }

  return listDisplay.join(', ')
}
