import { gql } from '@apollo/client'

export const getProductDuplicateQuery = gql`
  query ProductDuplicate($supplierId: Int) {
    getProductDuplicate(supplierId: $supplierId) {
      _cursor
      id
      primaryProduct {
        _cursor
        id
        name
        packSize
        unitValue
        price
        unit {
          _cursor
          id
          name
        }
        status
        code
        updatedAt
        supplier {
          _cursor
          id
          name
        }
      }
      duplicateProduct {
        _cursor
        id
        name
        packSize
        unitValue
        price
        unit {
          _cursor
          id
          name
        }
        status
        code
        updatedAt
        supplier {
          _cursor
          id
          name
        }
      }
    }
    getProductDuplicateListSummary(supplierId: $supplierId) {
      matchesRemaining
      matchesChecked
      total
    }
    getProductDuplicateSuppliers {
      id
      name
      count
    }
  }
`

export const updateProductDuplicateMutation = gql`
  mutation UpdateProductDuplicateMutation(
    $merge: Boolean!
    $primaryId: Int!
    $duplicateId: Int!
    $productMergeId: Int!
    $diffPrimaryId: Boolean!
  ) {
    updateProductDuplicate(
      merge: $merge
      primaryId: $primaryId
      duplicateId: $duplicateId
      productMergeId: $productMergeId
      diffPrimaryId: $diffPrimaryId
    )
  }
`

export const createProductDuplicatesListMutation = gql`
  mutation createProductDuplicatesListMutation {
    createProductDuplicatesList
  }
`
