import { Badge, useMediaQuery } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { ChevronRight, ExpandLess, ExpandMore } from '@material-ui/icons'
import debounce from 'lodash.debounce'
import { findIndex, find, prop, remove, insert, isNil, isEmpty } from 'ramda'
import React, { ReactNode, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { Maybe, SortOrder } from 'api'
import {
  AddIcon,
  CircularProgress,
  CreateIcon,
  VisibilityIcon,
  IconButton,
  SearchIcon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  InputAdornment,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from 'components'
import { routes } from 'routes'
import { makeStyles } from 'styles'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  title: {
    flex: '1 1 100%',
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

type Record<K extends keyof any, T> = {
  [P in K]?: T
}
type OrderBy<T> = Record<keyof T, any | null | undefined>

interface Props<T extends OrderBy<T>> {
  count?: {
    skip?: number
    take?: number
  }
  loading: boolean
  data: (Record<string, any> | undefined)[]
  total: number
  title: string
  search?: (value: string) => void
  defaultSearch?: string
  checkboxes?: {
    label: string
    onChange: (key: string, value: boolean) => void
  }[]
  setNewParams?: (data: { skip: number; take: number }) => void
  create?: boolean
  view?: boolean
  edit?: boolean
  editUrl?: string
  actionHeader?: string
  actions?: (
    id: number,
    name: string,
    item: unknown,
    index: number,
  ) => JSX.Element
  hideable?: boolean
  hidePagination?: boolean
  orderBy?: Maybe<Array<T>>
  setOrderBy?: (orderBy: Array<T>) => void
  orderByWhitelist?: Array<keyof T>
  rowClass?: string
  renderCell?: (parameters: {
    value: T[keyof T]
    key: keyof T
    row: T
    rowIndex: number
    renderDefault: () => ReactNode
  }) => ReactNode
}

export const EnhancedTable = <T extends OrderBy<T>>({
  count,
  data,
  total,
  setNewParams,
  loading,
  search,
  defaultSearch,
  checkboxes,
  title,
  create,
  view,
  edit,
  editUrl,
  actionHeader,
  actions,
  hideable,
  hidePagination,
  orderBy,
  setOrderBy,
  orderByWhitelist,
  rowClass,
  renderCell = ({ renderDefault }) => renderDefault(),
}: Props<T>) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [show, setShow] = useState(!hideable ? true : false)
  const isMobile = useMediaQuery('(max-width:900px)')
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
    setNewParams?.({
      skip: newPage * (count?.take ?? 0),
      take: count?.take ?? 25,
    })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => setNewParams?.({ skip: 0, take: parseInt(event.target.value, 10) })

  const handleSearch = debounce((query: string) => {
    if (search) {
      search(query)
      handleChangePage(undefined, 0)
    }
  }, 500)

  const handleOrder = (key: keyof T) => {
    if (!orderBy || !setOrderBy) return

    // @ts-expect-error
    const item = find(prop(key), orderBy)
    if (item) {
      // @ts-expect-error
      const index = findIndex(prop(key), orderBy)
      const newArray = remove(index, 1, orderBy)
      const value = item[key]
      if (value === SortOrder.Asc) {
        return setOrderBy(
          insert(
            index,
            {
              [key]: SortOrder.Desc,
            } as T,
            newArray,
          ),
        )
      }
      if (value === SortOrder.Desc) {
        return setOrderBy(newArray)
      }
    }

    return setOrderBy([
      ...orderBy,
      {
        [key]: SortOrder.Asc,
      } as T,
    ])
  }

  const topRow = (data && data[0]) ?? {}

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
          {hideable && (
            <Button onClick={() => setShow(!show)} data-testid="toggleButton">
              {show ? 'Hide' : 'Show'}
            </Button>
          )}
          {create && (
            <IconButton
              style={{ marginRight: 10 }}
              edge="end"
              color="inherit"
              aria-label="create"
              onClick={() => navigate(`${routes.Create}`)}
            >
              <AddIcon />
            </IconButton>
          )}

          {checkboxes?.length &&
            checkboxes.map((cb, index) => (
              <FormControlLabel
                key={index}
                value="end"
                control={
                  <Checkbox
                    data-testid={`checkbox-${index}`}
                    color="primary"
                    onChange={(e) => {
                      cb.onChange(cb.label, e.target.checked)
                    }}
                  />
                }
                label={cb.label}
                labelPlacement="end"
              />
            ))}

          {search && (
            <TextField
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleSearch(event.target.value)
              }
              defaultValue={defaultSearch}
              variant="outlined"
              InputProps={{
                endAdornment: loading && (
                  <InputAdornment position="end">
                    <CircularProgress size="1rem" />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Toolbar>
        {(!hideable || (hideable && show)) && (
          <React.Fragment>
            <TableContainer
              style={{
                maxHeight: 'calc(100vh - 260px)',
              }}
            >
              <Table
                style={{ minWidth: isMobile ? '100%' : undefined }}
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                stickyHeader
                aria-label="table"
              >
                <TableHead>
                  {view && <TableCell style={{ padding: 5 }}>View</TableCell>}
                  {edit && <TableCell style={{ padding: 5 }}>Edit</TableCell>}
                  {Object.keys(topRow).map((key: keyof typeof topRow) => {
                    if (key === 'rowClass') return
                    let shouldOrder = false
                    let item = undefined
                    let index = -1
                    const formattedKey = key.replace(
                      /[A-Z]/g,
                      (letter: string) => `_${letter.toLowerCase()}`,
                    ) as keyof T

                    if (orderBy && orderByWhitelist?.includes(formattedKey)) {
                      shouldOrder = true
                      // @ts-expect-error
                      item = find(prop(formattedKey), orderBy)
                      // @ts-expect-error
                      index = findIndex(prop(formattedKey), orderBy)
                    }

                    return (
                      <TableCell
                        key={`header-${key}`}
                        style={{
                          cursor: shouldOrder ? 'pointer' : 'undefined',
                          padding: 5,
                        }}
                        onClick={
                          shouldOrder
                            ? () => handleOrder(formattedKey)
                            : undefined
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          {key}
                          {item && shouldOrder && (
                            <Badge
                              badgeContent={
                                (orderBy?.length ?? 0) > 1 ? index + 1 : 0
                              }
                              color="primary"
                              overlap="circle"
                            >
                              {item[formattedKey] === SortOrder.Asc ? (
                                <ExpandMore />
                              ) : (
                                <ExpandLess />
                              )}
                            </Badge>
                          )}
                          {!item && shouldOrder && (
                            <Badge
                              badgeContent={0}
                              color="primary"
                              overlap="circle"
                            >
                              <ChevronRight />
                            </Badge>
                          )}
                        </div>
                      </TableCell>
                    )
                  })}
                  {actions && <TableCell>{actionHeader}</TableCell>}
                </TableHead>
                <TableBody>
                  {loading && (isNil(data) || isEmpty(data)) ? (
                    <CircularProgress />
                  ) : (
                    data?.map(
                      (
                        row: Record<string, unknown> | undefined,
                        index: number,
                      ) => (
                        <TableRow
                          className={
                            row?.rowClass && rowClass ? rowClass : undefined
                          }
                          hover={!row?.rowClass}
                          role="checkbox"
                          tabIndex={-1}
                          key={`row-${index}`}
                        >
                          {view && (
                            <TableCell style={{ padding: 5 }}>
                              <Link
                                to={`${row?.id || ''}`}
                                style={{
                                  color: 'unset',
                                }}
                              >
                                <IconButton
                                  style={{ marginRight: 10 }}
                                  edge="end"
                                  color="inherit"
                                  aria-label="create"
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Link>
                            </TableCell>
                          )}

                          {edit && (
                            <TableCell style={{ padding: 5 }}>
                              <Link
                                to={`${editUrl || ''}${routes.Edit}/${row?.id}`}
                                style={{
                                  color: 'unset',
                                }}
                              >
                                <IconButton
                                  style={{ marginRight: 10 }}
                                  edge="end"
                                  color="inherit"
                                  aria-label="create"
                                >
                                  <CreateIcon />
                                </IconButton>
                              </Link>
                            </TableCell>
                          )}

                          {row &&
                            Object.entries(row as T).map(
                              ([key, value]) =>
                                key !== 'rowClass' && (
                                  <TableCell
                                    key={`${row?.id}-${key}`}
                                    style={{ padding: 5, whiteSpace: 'nowrap' }}
                                  >
                                    {renderCell({
                                      key: key as keyof T,
                                      renderDefault: () => (
                                        <>{value as string}</>
                                      ),
                                      row: row as T,
                                      rowIndex: index,
                                      value: value as T[keyof T],
                                    })}
                                  </TableCell>
                                ),
                            )}

                          {actions &&
                            actions(
                              row?.id as number,
                              row?.name as string,
                              row,
                              index,
                            )}
                        </TableRow>
                      ),
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!hidePagination && (
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={total}
                rowsPerPage={count?.take ?? 25}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </React.Fragment>
        )}
      </Paper>
    </div>
  )
}
