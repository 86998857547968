import React, { useState, useEffect } from 'react'

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  CircularProgress,
} from 'components'
import { LIGHT_GREY } from 'styles/colors'
export const CustomTable = ({
  loading,
  data,
  headers,
  emptyMessage,
  onRowClick,
  pagination,
}: {
  loading?: boolean
  data?: any[]
  headers: string[]
  emptyMessage: string
  onRowClick?: (rowDate: any) => void
  pagination?: {
    count?: {
      skip?: number
      take?: number
    }
    total: number
    setNewParams: (data: { skip: number; take: number }) => void
  }
}) => {
  const [page, setPage] = useState(0)
  const handleChangePage = (_event: unknown, newPage: number) => {
    if (!pagination) return
    setPage(newPage)
    pagination.setNewParams({
      skip: newPage * (pagination.count?.take ?? 0),
      take: pagination.count?.take ?? 25,
    })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) =>
    pagination &&
    pagination.setNewParams({ skip: 0, take: parseInt(event.target.value, 10) })

  return (
    <TableContainer style={{ marginBottom: 20 }}>
      {loading && (
        <div
          style={{
            height: '50vh',
            margin: 200,
            position: 'absolute',
            top: 20,
            width: '50vw',
          }}
        >
          <CircularProgress />
        </div>
      )}
      {(data || headers) && (
        <Table size="small">
          <TableHead>
            <TableRow
              style={{
                backgroundColor: LIGHT_GREY,
              }}
            >
              {headers.map((key, index) => (
                <TableCell key={key}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              backgroundColor: 'white',
            }}
          >
            {data && data.length ? (
              data.map((row, index) => (
                <TableRow
                  onClick={() => onRowClick && onRowClick(row)}
                  key={`row${index}`}
                  style={{
                    cursor: onRowClick ? 'pointer' : undefined,
                    padding: 50,
                  }}
                >
                  {headers.map((key) => (
                    <TableCell key={key}>{row[key]}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={headers.length}
                  style={{ textAlign: 'center' }}
                >
                  {emptyMessage}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {pagination && data && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={pagination.total}
          rowsPerPage={pagination.count?.take ?? 10}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  )
}

export default CustomTable
