import { ApolloProvider } from '@apollo/client'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { client } from 'api'
import { DayJsUtils, LocalizationProvider } from 'components'
import { Routes } from 'routes'
import { muiTheme, theme } from 'styles'

import 'react-toastify/dist/ReactToastify.css'

dayjs.extend(utc)

const InnerContainer = () => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <LocalizationProvider dateAdapter={DayJsUtils}>
          <CssBaseline />
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
          <ToastContainer />
        </LocalizationProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  </ApolloProvider>
)

export const Container = Sentry.withProfiler(InnerContainer)
